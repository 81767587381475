html{
    height: 100% !important;
    position: absolute;
}
body{
    height: 100%;
}
#root{
    height: 100% !important;
}
#app{
    height: 100%;
}
#outer-container{
    height: 100%;
}
#page-wrap{
    height: 100%;
}
.side-wrapper{
    height: 100%;
}
.main-wrapper{height: 95%}
header{
    height: 5% !important;
}