
.App{
    width: 100% !important;
    margin: 0 auto;
    /*padding-left: 16px;*/
    /*padding-right: 16px;*/
    display: flex;
    height: 100% !important;
    position: relative;
}


/*.AppTitle{*/
/*    margin-top: 1rem;*/
/*    font-size: 30px;*/
/*    font-weight: 700;*/
/*}*/
.todoBlock{
    width: 15%;
    min-width: 250px;
    border-right: 1px solid rgba(110,110,110, 0.1);
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;

}
.btnBlock{
    display: flex;
    width: 100%;
    margin: 4px 0;

}
.addListBtn{
    align-self: center;
    width: 70%;
    min-height: 42px !important;

}
.exportBtn{

    margin-left: 4px;
    min-height: 42px !important;
    width: 30% !important;
}
.actionBlock{
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 8px
}
.checklistBlock{
    margin-top: 8px;
}
.todoList{
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    gap: 4px;
}
.todoItem{
    cursor: pointer;

    padding: 8px 0 4px 8px;
    border-bottom: 1px solid rgba(110,110,110, .1);
}
.todoItem:hover{
    background-color: rgba(110,110,110, .1);
}