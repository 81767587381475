.App{
    width: 86% !important;
    margin: 0 auto;
    padding-left: 16px;
    padding-right: 16px;
    height: 100%;
    display: flex;
    flex-direction: column;

}
header{
    font-size: 30px;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    padding: 26px;
    border-bottom: 1px solid rgba(204, 204, 204, 0.5);
    cursor: pointer;
}
.mainBlock{
    display: flex;
    justify-content: space-between;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 20px;

}
.firstBlock{
    padding: 20px;
    width: 48%;
    overflow-y: auto;
    background-color: white;
    border-radius: 20px;
    -webkit-box-shadow: 8px 8px 19px 0px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 8px 8px 19px 0px rgba(34, 60, 80, 0.2);
    box-shadow: 8px 8px 19px 0px rgba(34, 60, 80, 0.2);
}
.formBlock{
    max-height: 70vh;
    overflow-y: auto;
}
.secondBlock{
    -webkit-box-shadow: 8px 8px 19px 0px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 8px 8px 19px 0px rgba(34, 60, 80, 0.2);
    box-shadow: 8px 8px 19px 0px rgba(34, 60, 80, 0.2);
    width: 48%;
    background-color: white;
    border-radius: 20px;
    padding: 8px 20px 0 20px;
    height: fit-content;
    max-height: 90vh;
}
.formControl{
    margin-bottom: 8px;
}
.cardFooterActions{

}
.cardFooter{
    display: flex;
    justify-content: flex-end;
}
.loader{
    height: 80vh;
    background-color: #00bce8;
}
.tabsList{flex-wrap: wrap}