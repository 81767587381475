.App{
    width: 86% !important;
    margin: 0 auto;
    padding-left: 16px;
    padding-right: 16px;
}
.AppTitle{
    font-size: 30px;
    font-weight: 700;
}
html{
    /*overflow-x: auto;*/
}
header{
    padding: 1rem !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 0 !important;
}
.actionBlock{
    display: flex;
    align-items: center;
}
.appsWrapper{
    padding-top: 1rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
}
.accWrapper{
    padding-top: 1rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
}
.accItem{

    width: 100%;
    display: flex;
    flex-direction: column;

    border: 0;
    justify-content: space-between;
}


.appItem{
    padding: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.appItemProp{
    display: flex;
    font-size: 14px;
    white-space: nowrap;
    flex-direction: row;
    justify-content: space-between;
    gap: 8px;

}
.appItemPropBlock{
    flex-direction: row;
    display: flex;
    gap: 1rem;
    justify-self: end;
}

.appName{
    font-weight: 500;
    color: #004e69;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    margin-left: 1rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.appPackage{
    left: 90%;
    position: absolute;
    font-size: 12px;
    font-style: italic;
    color: #6e6e6e;
    /*display: flex;*/
    display:inline-block;
    width:200px;
    text-align: left;
    white-space: nowrap;
    overflow:hidden !important;
    text-overflow: ellipsis;
}
.appName:hover{
    font-weight: 700;
    text-decoration: underline;
    color: #2a2a2a;
    cursor: pointer;
}
.appActionBlock{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 20%;
    gap: 1rem;
}
.actionBlockAcc{
    padding: 0 !important;
    display: flex;
    justify-content: center;

    border-radius:6px;
}
.appImg{
    width: 48px;
    height: 48px;
}
.appItemMainBlock{
    position: relative;
    display: flex;
    width: 20%;

}
.paginationBlock{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
}
.pagination {
    display: flex;
    justify-content: space-between;
    list-style: none;
    width: fit-content;
    cursor: pointer;
    padding: 10px;
    margin: 16px auto 0;
}

.pagination a {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #00bce8;
    color: #00bce8;
}

.paginationLink {
    font-weight: bold;
    margin: 0 0.5rem 0 0.5rem;
}

.paginationLinkActive a {
    color: #fff;
    background: #0080e8;
}

.paginationLinkDisabled a {
    color: rgb(198, 197, 202);
    border: 1px solid rgb(198, 197, 202);
}

.appInfoBlock{
    width: 30%;
    text-align: center;
}
.greenStatus{
    color: #007300;
}
.blueStatus{
    color: #0055b0;
}
.accPanel{
    width: 100% !important;
    display: flex;
    border:1px solid #efefef ;
    border-top:0 ;
    border-bottom-left-radius:8px ;
    border-bottom-right-radius:8px ;
    margin: -1px auto 0;
}
.accPanelFirstBlock{
    white-space: pre-line;
    display: flex;
    justify-content: space-between;
    width: 100%;

}
.backlogInfo{
    display: -webkit-box;
    -webkit-line-clamp: 5;
    line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.searchMainBlock{
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 0.5rem;
    position: relative;

}
.searchIcon{
    width: 16px !important;
}
li span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.searchBlock{
    position: absolute;
    top: 100%;
    width: 100%;
    border: 1px solid #a4a4a4;
    background-color: white;
    overflow-y: auto;
    max-height: 40vh;
}
.searchBlockHidden{
    visibility: hidden;
}

.searchInput{
    width: 360px;
}
.searchInput input{
    border: 0 !important;
    outline: 0 !important;
    font-size:  16px;
    width: 100%;
}

.searchAppItem{
    font-size: 16px;
    padding: 0.5rem;
    background-color: white;
    border-bottom: 1px solid #c7c7c7;
}
.searchAppItem:hover{
    background-color: #f1f0f0;
}
.appNameSearchBlock{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}