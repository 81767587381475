.mainHeader{
    padding: 0;
    margin: 0;
    width: 100vw !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 10%;
    padding: 0 !important;
    border-bottom: 1px solid rgba(204, 204, 204, 0.5) !important;
}
.headerPartFirst{
    display: flex;
    align-items: center;
}
.appTitle{
    font-size: 1.2rem;
}
.menuList{
    font-size: 1rem;
}
.exitBtn{
    color: red !important;
}
.avatarItem{
    width: 2rem !important;
    height: 2rem !important;

}
.avatarItem {
    font-size: 8px !important;
    margin: 0 8px 0 8px;
}
.avatarButton{
    font-size: 8px !important;
}