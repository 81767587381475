.App{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
}
.mainBox{
    gap: 1rem;
    display: flex;
    flex-direction: column;
    width: 100vw;
    max-width: 368px;
    padding: 8px;
}
.appTitle{
    margin: 0 auto;
}
.errorText{
    font-weight: 700;
    color: #da0000;
    font-size: 14px;
}