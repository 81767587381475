.optBlock{
    width: 80%;
    display: flex;
    gap: 8px;
    margin: 0 auto;
    align-items: flex-end;
    justify-content: space-evenly;
}
.formItem{
    width: 120px !important;
}
.formTitle{
    font-size: 12px;
    white-space: nowrap;
}
.submitBtn{
    align-self: flex-end;
}
.reqStar{
    font-size: 12px;
    color: #bb0000;
}
.datepicker{
    height: fit-content!important;
}
.datePickerBlock{
    display: flex;
    flex-direction: column;
}
.datePickerItem{
    justify-content: space-between;
    display: flex;
    gap: 4px;
}
.checkboxBlock{
    display: flex;
    flex-direction: column;


}
.checkboxItem{
    white-space: nowrap;
    justify-content: space-between;
    display: flex;
    gap: 4px;
}