.App{
    position: relative;
    width: 100%;
    overflow-y: scroll;
    height: 100%;
}
.todoTitle{
    padding: 8px 16px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(110,110,110, .2);
}
.taskList{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.loadingBlock{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(167, 167, 167, 0.5);
}