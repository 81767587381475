.taskItem{
    width: 100%;
    height: fit-content;
    cursor: pointer;
    padding: 4px 0 4px 8px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.taskItem:hover{
    width: 100%;
    background-color: rgba(110,110,110, .1);
}
.radioBtn{

}
.radioBlock{
    width: 100%;
    cursor: pointer;
    height: 100% !important;
    min-height: 40px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    text-wrap: normal;
    word-break: break-word;
}
.taskActionBlock{
    width: fit-content;
    margin-left: 8px;
    display: flex;
}