.cardFooterActions{

}
.cardFooter{
    display: flex;
    justify-content: flex-end;
}
.formControl{
    margin-bottom: 8px;
}
.mainForm{
    max-height: 50vh;
    overflow-y: auto;
}